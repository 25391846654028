import type { PropsWithChildren } from "react"

interface ErrorBannerLayoutProps extends PropsWithChildren {
  horizontalPadding?: number
}

export default function ErrorBannerLayout({
  horizontalPadding = 4,
  children,
}: ErrorBannerLayoutProps) {
  return (
    <div
      className={`bg-cream border border-beige mb-6 py-2 px-${horizontalPadding} text-center`}
    >
      <span className="text-body-copy text-sm">{children}</span>
    </div>
  )
}
